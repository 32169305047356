<template>
  <v-app>
    <div class="box">
      <v-app-bar class="appbar-box" app color="rgb(51,51,51)" flat  style="z-index:100;">
      <div class="d-flex align-center" >
        <v-img class="shrink mr-2" contain src="./assets/img/navbar/AGX Logo-White.svg" width="94" height="80" />

      </div>

      <v-spacer></v-spacer>

      <!-- <v-btn class="text-none mr-2" dark color="#fff" text>
        Sign In
      </v-btn>
      <v-btn class="text-none" dark color="#E5A80E" depressed>
        Get Started
      </v-btn> -->
    </v-app-bar>
    </div>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
  .box {
    width: 100%;
    height: 100px;
    position: fixed;
    background-color: rgb(51,51,51);
    z-index: 100;
  }
.appbar-box {
  height: 100px !important;
  width: 1800px;
  padding-top: 18px;
  margin: 0 auto;
}
</style>
