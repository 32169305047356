import { render, staticRenderFns } from "./UseCase.vue?vue&type=template&id=38ff17db&scoped=true&"
import script from "./UseCase.vue?vue&type=script&lang=js&"
export * from "./UseCase.vue?vue&type=script&lang=js&"
import style0 from "./UseCase.vue?vue&type=style&index=0&id=38ff17db&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38ff17db",
  null
  
)

export default component.exports