<template>
  <div style="background:#fff;" class="py-16">
    <v-container>
      <div v-if="$vuetify.breakpoint.mobile">
        <LaunchDay></LaunchDay>
        <RegistForm class="mt-6"></RegistForm>
      </div>
      <v-row v-else>
        <v-col cols="6" >
          <RegistForm></RegistForm>
        </v-col>
        <v-col cols="6" class="pl-10">
          <LaunchDay class="mt-8"></LaunchDay>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import RegistForm from './RegistForm.vue';
import LaunchDay from './LaunchDay.vue';
export default {
    components: { RegistForm, LaunchDay }
}
</script>

<style scoped>


</style>