<template>
  <div style="background: #333333" class="py-16">
    <!-- #333333 -->
    <v-container style="color: #fff">
      <div class="d-flex" :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-row'">
        <div>
          <div class="cu">SOCIAL</div>
          <div class="d-flex flex-row mt-10">
            <v-btn color="#F9BF16" fab depressed width="48" height="48" href="https://www.facebook.com">
              <img style="height: 16px" src="../assets/img/banner/facebook.png" />
            </v-btn>
            <v-btn class="ml-2" color="#F9BF16" fab depressed width="48" height="48" href="https://www.twitter.com">
              <img style="height: 16px" src="../assets/img/banner/twitter.png" />
            </v-btn>
            <v-btn class="ml-2" color="#F9BF16" fab depressed width="48" height="48" href="https://www.instagram.com">
              <img style="height: 16px" src="../assets/img/banner/instagram.png" />
            </v-btn>
          </div>
          <img src="../assets/img/footer/logo.png" style="height: 80px" class="mt-8" />
        </div>
        <div style="" :style="
          $vuetify.breakpoint.mobile
            ? 'margin-top:40px;'
            : 'margin-left:100px;'
        ">
          <div>General lnquiries</div>
          <div style="opacity: 0.5" class="mt-10">
            <div>Unit 1B Ealing Broadway, Earling Crescent,</div>
            <div>Bryanston, Gauteng, 2191, South Africa</div>
          </div>
          <!-- <div class="mt-6">support@africaglobalxchange.com</div>
          <div class="mt-6">+1(234)-1111-1111</div> -->
        </div>
        <v-spacer></v-spacer>
        <div style="max-width: 300px" class="ContactFrom">
          <h1>Contact Us</h1>
          <div class="second">
            <!-- 联系表单 -->
            <el-form :rules="emailRules" :model="emailForm" ref="emailRef" class="contactform">
              <!-- name框 -->
              <el-form-item prop="username" class="nameform">
                <el-input type="name" placeholder="Name" v-model="emailForm.username" ></el-input>
              </el-form-item>
              <!-- Contact No框 -->
              <el-form-item prop="Contact" class="conform">
                <el-input placeholder="Contact No" v-model="emailForm.Contact"></el-input>
              </el-form-item>
              <!-- Email Address输入框 -->
              <el-form-item prop="emailArs">
                <el-input placeholder="Email Address" v-model="emailForm.emailArs"></el-input>
              </el-form-item>
              <!-- Subject 输入框 -->
              <el-form-item prop="Subject">
                <el-input placeholder="Subject" v-model="emailForm.Subject"></el-input>
              </el-form-item>
              <!-- Your Message 输入框 -->
              <el-form-item prop="msg">
                <el-input type="textarea" placeholder="Your Message" v-model="emailForm.msg" ></el-input>
              </el-form-item>
              <!-- button 提交按钮 -->
              <el-form-item>
                <el-button type="warning" @click="emailReq">SUBMIT</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="d-flex mt-16" :class="
        $vuetify.breakpoint.mobile ? 'flex-column align-center' : 'flex-row'
      ">
        <div>Privacy Policy Terms & Conditions</div>
        <v-spacer></v-spacer>
        <div style="color: #fff; opacity: 0.5">
          © 2022 Africa Global Xchange
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import {emailApi } from '@/api/index.js'
export default {
  name: 'myfooter',
  data () {
    return {
      emailForm:{
        username: '', // 用户名
        Contact: '', // 联系方式
        emailArs: '', // 邮箱地址
        Subject: '', // 主题
        msg: '' // 发送的信息
      },
      emailRules: {
        // 用户名校验规则
        username: [
          {required: true, message: 'Please enter your name', trigger:'blur'},
          {
            pattern: /^[a-zA-Z0-9]{2,12}$/, // 匹配的正则
            message: '2 to 12 letters or numbers',
            trigger: 'blur' // 失焦做判断
          }
        ],
        // 联系人信息校验
        Contact: [
          {required: true, message: 'It has to be a number', trigger: 'blur'},
          {
            pattern: /^[0-9]*$/, // 只为数字
            message: 'It has to be a number' ,
            trigger: 'blur'           
          }
        ],
        // 邮箱地址校验
        emailArs: [
          {required: true, message: 'Please enter your email address', trigger: 'blur'},
          {
            pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            message: 'Please enter the correct email address',
            trigger: 'blur'
          }
        ],
        // 主题校验
        Subject: [
          {required: true, message: 'Please enter the correct email subject', trigger: 'blur'},
          {
            pattern: /^[\s\S]*.*[^\s][\s\S]*$/,
            message: 'Please enter the correct email subject',
            trigger: 'blur'
          }
        ],
        // 内容校验
        msg: [
          {required: true, message: 'Please enter the content of the email', trigger: 'blur'},
          {
            pattern: /^[\s\S]*.*[^\s][\s\S]*$/,
            message: 'Please enter the content of the email',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  methods: {
    // 联系我们处提交
     emailReq(){
    this.$refs.emailRef.validate(async vali => {
      // js兜底校验
      if(!vali) return false
      // 服务器返回的结果
      let res = await emailApi(this.emailForm)
      console.log(res);
      // // 失败的结果
      if(res.status !== 200) return this.$message.error(res.message)
      // 成功的结果
      this.$message.success('send an e-mail success')
      // 清空表单
      this.emailForm.username=''
      this.emailForm.Contact=''
      this.emailForm.emailArs=''
      this.emailForm.Subject=''
      this.emailForm.msg=''
    })
    }
  },
}
</script>

<style scoped>
.ContactFrom input {
  padding-left: 10px;
  border: none;
  background-color: rgb(71, 71, 71);
  margin-top: 10px;
  border-radius: 4px;
  height: 30px;
  font-size: 12px;
  width: 310px;
}

.second {
  display: flex;
  width: 310px;
  justify-content: space-between;
}

.second input:first-child {
  width: 150px;
}

.second input:last-child {
  width: 150px;
}

#lastInp {
  padding-left: 10px;
  border: none;
  background-color: rgb(71, 71, 71);
  margin-top: 10px;
  border-radius: 4px;
  font-size: 12px;
  width: 310px;
  padding-top: 5px;
  margin-bottom: 5px;
}

.ContactFrom button {
  border: none;
  background-color: rgb(249, 191, 22);
  width: 310px;
  height: 30px;
  border-radius: 5px;
  color: aliceblue;
  font-size: 12px;
}

.cu {
  font-family: 'Bebas Neue';
  width: 50px;
  height: 32px;
  font-size: 18px;
}
.contactform {
  position: relative;
}
.nameform {
  width: 150px;
}
.conform {
  width: 150px;
  position: absolute;
  right: 0;
  top: 0;
}
/deep/.el-input__inner {
  background-color: rgb(71,71,71);
  border: none;
  height: 30px;
  color: white;
}
/deep/.el-textarea__inner {
  background-color: rgb(71,71,71);
  height: 100px;
  border: none;
  color: white;
}

</style>
