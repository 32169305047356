<template>
  <div style="background:#F9BF16;position: relative;">
    <v-container style="color:#fff;padding-top:100px;padding-bottom: 100px;">
      <div class="d-flex "
        :class="$vuetify.breakpoint.mobile?'flex-column':'flex-row justify-space-between align-center'">
        <div>
          <div class="text-uppercase" style="font-size:35px;font-weight:bold;">Available, get it now!</div>
          <div>Connecting people, Enabling trade, Creating markets</div>
        </div>
        <div style="position:relative;"
          :class="$vuetify.breakpoint.mobile?'mt-8':''">
          <!-- <v-btn class="text-none px-10"
            style="z-index:2;"
            depressed
            rounded
            large
            dark>
            Sign Up
          </v-btn> -->
          <img src="../assets/img/join/mask2.png" class="mask2"></img>
        </div>
      </div>
      <img src="../assets/img/join/mask1.png" class="mask1">
      
    </v-container>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.mask1{
  position: absolute;
  top: -40px;
  right: 0;
  z-index: 1;
  width: 390px;
}
.mask2{
  position: absolute;
  top: 0;
  left: -180px;
  z-index: 1;
  width: 244px;
}
.text-uppercase {
  font-family: 'Bebas Neue';
  letter-spacing: 1px;
}
</style>