<template>
  <div>
    <div class="text-uppercase" style="font-size:34px;font-weight:bold;color:#333333;">Launch Date Q 2,2023</div>
    <v-row style="max-width:450px;" class="mt-3">
      <v-col cols="3" style="color:#333333;" class="text-center">
        <div class="ba">
          <div>{{days}}</div>
        </div>
        <div style="font-weight:bold;" class="mt-3">DAYS</div>
      </v-col>
      <v-col cols="3" style="color:#333333;" class="text-center">
        <div class="ba">
          <div>{{hours}}</div>
        </div>
        <div style="font-weight:bold;" class="mt-3">HOURS</div>
      </v-col>
      <!-- <v-col cols="3" style="color:#333333;" class="text-center">
        <div class="ba">
          <div>54</div>
        </div>
        <div style="font-weight:bold;" class="mt-3">MINUTES</div>
      </v-col>
      <v-col cols="3" style="color:#333333;" class="text-center">
        <div class="ba">
          <div>11</div>
        </div>
        <div style="font-weight:bold;" class="mt-3">SECONDS</div>
      </v-col> -->
    </v-row>

    <div class="content mt-10">Get early access and updates on AGX and AUAX StableCoin.</div>
    <p class="content ">Please use the form to register</p>
    <div class="content mt-6">Don’t leave your Friends Behind - Invite 5 friends. First 100</div>
    <p class="content">referral registrations to win 20 AUAX StableCoin each</p>
    <div class="mt-16">
      <img class="social" src="../assets/img/regist/fb.png"></img>
      <img class="social ml-2" src="../assets/img/regist/twitter.png"></img>
      <img class="social ml-2" src="../assets/img/regist/ins.png"></img>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return { 
      days: 0, // days
      hours: 0, // hours
    }
  },
  created() {
    this.showtime();
    setInterval (() =>{
      this.showtime();
    }, 1000 * 60); // 一分钟刷新一下
  },
  methods: {
    showtime() {
      const nowtime = new Date()
      const endtime = new Date("2023/04/30 00:00:00")
      const lefttime = endtime.getTime() - nowtime.getTime()
      if(lefttime>0) {
        this.days = Math.floor(lefttime/(1000*60*60*24))  //days
        this.hours = Math.floor(lefttime/(1000*60*60)%24)  //hours
      }
      
    }
  }
}
</script>

<style scoped>
.ba{
  border: 1px solid #333333;
  border-radius: 4px;
  font-weight: bolder;
  font-size: 30px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.content{
  color: #333333;
  opacity: 0.5;
}
.social{
  width: 32px;
  height: 32px;
}
.text-uppercase,.mt-3  {
  font-family: 'Bebas Neue';
  letter-spacing: 1px;
}

</style>