import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('div',{staticClass:"box"},[_c(VAppBar,{staticClass:"appbar-box",staticStyle:{"z-index":"100"},attrs:{"app":"","color":"rgb(51,51,51)","flat":""}},[_c('div',{staticClass:"d-flex align-center"},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"contain":"","src":require("./assets/img/navbar/AGX Logo-White.svg"),"width":"94","height":"80"}})],1),_c(VSpacer)],1)],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }