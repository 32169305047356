<template>
  <div class="elevation-17 pa-4 mx-3 rounded-lg">
    <div class="text-uppercase" style="font-size:34px;font-weight:bold;color:#333333;">Registration Form</div>
    <!-- <div class="form-field mt-6" >Name</div>
    <v-text-field class="mt-2" outlined placeholder="Name" dense hide-details>
    </v-text-field>
    <div class="form-field mt-4">Surname</div>
    <v-text-field class="mt-2" outlined placeholder="Surname" dense hide-details>
    </v-text-field>
    <div class="form-field mt-4">Email address</div>
    <v-text-field class="mt-2" outlined placeholder="Email" dense hide-details>
    </v-text-field>
    <div class="form-field mt-4">Phone no.</div>
    <v-text-field class="mt-2" outlined placeholder="Number" dense hide-details>
    </v-text-field>
    <div class="form-field mt-4">enter friends details</div>
    <v-textarea class="mt-2" outlined no-resize placeholder="Friends Details" dense hide-details>
    </v-textarea>
    <v-btn color="#F9BF16" class="text-none mt-6 mb-4" large dark rounded depressed block>
      Send
    </v-btn> -->

    <el-form :rules="emailRules" :model="emailForm" ref="emailRef" class="contactform">
              <!-- name框 -->
              <el-form-item prop="username" class="form-field mt-6" label="Name">
                <el-input type="name" class="mt-2" placeholder="Name" v-model="emailForm.username" ></el-input>
              </el-form-item>
              <!-- Surname框 -->
              <el-form-item prop="Surname" class="form-field mt-4" label="Surname">
                <el-input  class="mt-2" placeholder="Surname" v-model="emailForm.Surname"></el-input>
              </el-form-item>
              <!-- Email Address输入框 -->
              <el-form-item prop="Email" class="form-field mt-6" label="Email address">
                <el-input class="mt-2" placeholder="Email" v-model="emailForm.Email"></el-input>
              </el-form-item>
              <!-- Number 输入框 -->
              <el-form-item prop="Number" class="form-field mt-6" label="Phone no."> 
                <el-input class="mt-2" placeholder="Number" v-model="emailForm.Number"></el-input>
              </el-form-item>
              <!-- Your Message 输入框 -->
              <el-form-item prop="msg" class="form-field mt-6" label="enter friends details">
                <el-input class="mt-2" type="textarea" placeholder="Friends Details" v-model="emailForm.msg" ></el-input>
              </el-form-item>
              <!-- button 提交按钮 -->
              <el-form-item>
                <el-button type="warning" @click="emailReq" size="large">Send</el-button>
              </el-form-item>
            </el-form>
  </div>
</template>

<script>
import { emailsApi } from '@/api/index.js'
export default {
  data () {
    return { // 表单数据
      emailForm: {
        username: '',
        Surname: '',
        Email: '',
        Number: '',
        msg: ''
      },
      // 校验规则
      emailRules: {
        Number: [
          {required: true, message: 'It has to be a number', trigger: 'blur'},
          {
            pattern: /^[0-9]*$/, // 只为数字
            message: 'It has to be a number' ,
            trigger: 'blur'           
          }
        ],
        // 邮箱地址校验
        Email: [
          {required: true, message: 'Please enter your email address', trigger: 'blur'},
          {
            pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            message: 'Please enter the correct email address',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  methods: {
    // 邮箱发送
    emailReq() {
      this.$refs.emailRef.validate(async vlid => {
        if(!vlid) return false 
        const res = await emailsApi(this.emailForm)
        console.log(res);
        if(res.status !== 200) return this.$message.error('unknown error')
        this.$message.success('success')
        this.emailForm.username = ''
        this.emailForm.Surname = ''
        this.emailForm.Email = ''
        this.emailForm.Number = ''
        this.emailForm.msg = ''
      })
    }
  }
}
</script>

<style scoped>
.form-field {
  color: #333333;
  font-weight: bolder;
  text-transform: uppercase;
}

.text-uppercase {
  font-family: 'Bebas Neue';
  letter-spacing: 1px;
}
/deep/.el-input__inner {
  font-weight: 400;
  letter-spacing: .06em;
}
/deep/.el-form-item__label {
  color: black;
}
/deep/.el-textarea__inner {
  font-weight: 400;
  letter-spacing: .06em;
  min-height: 100px !important;
}
/deep/.el-button {
  width: 100%;
  border-radius: 20px;
}
</style>