import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/font/font.css'
import ElementUI from '@/elementUI'


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  ElementUI,
  render: function (h) { return h(App) }
}).$mount('#app')
