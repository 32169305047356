<template>
  <div class="home">
    <Banner></Banner>
    <Features></Features>
    <Aims></Aims>
    <UseCase></UseCase>
    <Regist></Regist>
    <Join></Join>
    <Footer></Footer>
  </div>
</template>

<script>
import Banner from '../components/Banner.vue';
import Features from '../components/Features.vue';
import Aims from '../components/Aims.vue';
import UseCase from '../components/UseCase.vue';
import Regist from '../components/Regist.vue';
import Join from '../components/Join.vue';
import Footer from '../components/Footer.vue';
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    Banner,
    Features,
    Aims,
    UseCase,
    Regist,
    Join,
    Footer
}
}
</script>
