<template>
  <v-container class="py-16" style="max-width:985px;">
    <div class="text-center">
      <span class="rounded-pill white--text px-4 py-2" style="background:#333333;">FEATURES</span>
      <div class="text-uppercase mt-6" style="font-size:50px;color:#333333;font-weight:bold;">Product Services / Features</div>
      <div style="color:#333333;opacity: 0.5;" class="mt-4">AUAX - the world's first globally rated StableCoin</div>
    </div>
    <div class="mt-16">
      <v-row>
        <v-col :cols="$vuetify.breakpoint.mobile?12:6" class="feature">
          <img class="f-logo" src="../assets/img/banner/feature1.png">
          <div class="f-title">“AUAX” StableCoin</div>
          <div class="f-subtitle">A fully regulated, audited, well de-risked, and secured StableCoin, collaterised by a bankruptcy remote reserve basket of fiat currencies (1:1) that will be dully rated by one of the global rating agencies</div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile?12:6" class="feature">
          <img class="f-logo" src="../assets/img/banner/feature2.png">
          <div class="f-title">AGX DIGITAL EXCHANGE</div>
          <div class="f-subtitle">AGX Digital Exchange- enables Peer to Peer, Business to Business, and Business to Consumer transactions and payments, instantaneously anywhere anytime with strong compliance to KYC and Anti Money Laundering (AML), and customer protection provisions</div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile?12:6" class="feature">
          <img class="f-logo" src="../assets/img/banner/feature3.png">
          <div class="f-title">AGX Academy</div>
          <div class="f-subtitle">Educational content, training, and generation of knowledge products to boost the adoption and usage of AUAX StableCoin as well as promote financial sector knowledge and applications</div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile?12:6" class="feature">
          <img class="f-logo" src="../assets/img/banner/feature4.png">
          <div class="f-title">SME Finance</div>
          <div class="f-subtitle">Offer access to affordable financial services to over forty million+ SMEs in Africa faced with over one-third of a trillion dollar funding gap</div>
        </v-col>
      </v-row>
    </div>

    <div class="text-center mt-16">
      <span class="rounded-pill white--text px-4 py-2" style="background:#F9BF16;">OBJECTIVE</span>
      <div class="text-uppercase mt-6" style="font-size:38px;color:#333333;font-weight:bold;">The Objective of AGX</div>
      <div style="color:#333333;opacity: 0.5;" class="mt-4">AGX is on a mission to address inefficiencies faced by traditional financial services and create value by mainstreaming the benefits of crypto, especially StableCoin into the unique context of every day use cases in Africa via cost effective and efficient financial services, whilst supported by traditional trust infrastructure</div>
    </div>
  </v-container>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.feature{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.feature .f-logo{
  width: 100px;
}
.feature .f-title{
  color:#333333;
  font-size:20px;
  font-weight: bold;
  margin-top: 12px;
  text-transform: uppercase;
}
.feature .f-subtitle{
  color:#333333;
  opacity: 0.5;
  max-width:450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
}
.rounded-pill {
  font-family: 'DMSans-Regular';
}

.text-uppercase {
  font-family: 'Bebas Neue';
}
.f-title {
  font-family: 'Bebas Neue Regular';
}
</style>