// 基于axios封装请求的函数
import axios from "axios"

// 使用axios.create() 创建一个带配置项的自定义axios函数
// 请求基地址 baseUrl + 后续地址
const myAxios = axios.create({
  baseURL: '/api'
})

// 导出
export default myAxios
