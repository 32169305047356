<template>
  <div style="background:#fff;" class="py-16">
    <v-container>
      <v-row>
        <v-col cols="12" v-if="$vuetify.breakpoint.mobile">
          <img src="../assets/img/usecase/usecase1.png" style="width:100%;">
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile?12:6" class="usecase">
          <div>
            <span class="rounded-pill white--text px-4 py-2 text-uppercase" style="background:#242725;">Use case</span>
          </div>
          <div class="u-title">SOME USE CASES</div>
          <div class="u-title">PEER-TO-PEER</div>
          <div class="u-content">Is it nice that Abdu is compelled by the Big Sharks to spend 3 times the cost (the highest globally) to remit funds to his mum, to address her critical needs?</div>
          <div class="u-content">At AGX, we don’t think this is fair! that is why we are determined to enable more affordable, instantaneous and efficient remittance compared to traditional money (fiat currency) transfers, so Abdu’s mum can keep more value in her pocket</div>
        </v-col>
        <v-col cols="6" v-if="!$vuetify.breakpoint.mobile">
          <img src="../assets/img/usecase/usecase1.png" style="width:100%;">
        </v-col>
      </v-row>
      <v-row style="margin-top:100px;">
        <v-col :cols="$vuetify.breakpoint.mobile?12:6">
          <img src="../assets/img/usecase/usecase2.png" style="width:100%;">
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile?12:6" class="usecase">
          <div>
            <span class="rounded-pill white--text px-4 py-2 text-uppercase" style="background:#F9BF16;">Use case</span>
          </div>
          <div class="u-title">Business-to-Business</div>
          <div class="u-content">Won’t you agree that conducting business to business (domestic and cross border) transactions should not be a nightmare?</div>
          <div class="mt-10">
            <div class="d-flex flex-row">
              <div class="u-logo">
                <img src="../assets/img/usecase/material-symbols_add-business-outline.png">
              </div>
              <div style="flex:1;" class="ml-4">
                <div class="case-subtitle">At AGX</div>
                <div class="case-content">At AGX, we believe so!</div>
                <div class="case-content">Our solution enables seamless and instantaneous business to business transaction, transfers, and settlement.</div>
              </div>
            </div>
            <div class="d-flex flex-row mt-8">
              <div class="u-logo">
                <img src="../assets/img/usecase/vector.png">
              </div>
              <div style="flex:1;" class="ml-4">
                <div class="case-subtitle">Transaction</div>
                <div class="case-content">We take the worry of price volatility and value preservation off every transaction via the AUAX Stablecoin, so businesses can focus on their core delivery</div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row style="margin-top:100px;">
        <v-col :cols="$vuetify.breakpoint.mobile?12:6">
          <img src="../assets/img/usecase/usecase3.png" style="width:100%;">
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile?12:6" class="usecase">
          <div>
            <span class="rounded-pill white--text px-4 py-2 text-uppercase" style="background:#333333;">Benefits</span>
          </div>
          <div class="u-title">Key benefits</div>
          <div class="mt-10">
            <div class="d-flex flex-row">
              <div class="u-logo-dark">
                <span>01</span>
              </div>
              <div style="flex:1;" class="ml-4">
                <div class="case-subtitle">Less volatile, and More resilient</div>
                <div class="case-content">than single currency stablecoins, and local currencies</div>
              </div>
            </div>
            <div class="d-flex flex-row mt-8">
              <div class="u-logo-dark">
                <span>02</span>
              </div>
              <div style="flex:1;" class="ml-4">
                <div class="case-subtitle">Adaptable</div>
                <div class="case-content">to the unique specificities and use cases in the continent. Mitigates the monetary challenges faced by Developing countries, especially African economies – Inflation, currency convertibility etc.</div>
              </div>
            </div>
            <div class="d-flex flex-row mt-8">
              <div class="u-logo-dark">
                <span>03</span>
              </div>
              <div style="flex:1;" class="ml-4">
                <div class="case-subtitle">Strong Alignment</div>
                <div class="case-content">with the currency profile of Africa’s trading Partners, Sources of Investments (FDI) and remittances</div>
              </div>
            </div>
            <div class="d-flex flex-row mt-8">
              <div class="u-logo-dark">
                <span>04</span>
              </div>
              <div style="flex:1;" class="ml-4">
                <div class="case-subtitle">Boost to Financial Inclusion</div>
                <div class="case-content">stable value of exchange AUAX would lead to accelerated economic transformation, deepen integration, boost financial inclusion, and inclusive growth.</div>
              </div>
            </div>
            <div class="d-flex flex-row mt-8">
              <div class="u-logo-dark">
                <span>05</span>
              </div>
              <div style="flex:1;" class="ml-4">
                <div class="case-subtitle">Convertibility</div>
                <div class="case-content">Strong exchange Parity with Africa and Global (Fiat) currencies</div>
              </div>
            </div>
            <div class="d-flex flex-row mt-8">
              <div class="u-logo-dark">
                <span>06</span>
              </div>
              <div style="flex:1;" class="ml-4">
                <div class="case-subtitle">Transaction Efficiency and lower transaction cost</div>
                <div class="case-content">than traditional financial services</div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      
    </v-container>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.usecase{

}
.usecase .u-title{
  text-transform: uppercase;
  color: #333333;
  font-size: 30px;
  font-weight: bolder;
  margin-top: 20px;
}
.usecase .u-content{
  color: #333333;
  max-width: 450px;
  opacity: 0.5;
  margin-top: 20px;
  line-height: 32px;
}
.u-logo{
  border-radius: 12px;
  background: #F9BF16;
  width: 80px;
  height: 80px;
  text-align: center;
}
.u-logo-dark{
  border-radius: 12px;
  background: #333333;
  width: 80px;
  height: 80px;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.u-logo img{
  width: 40px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.case-subtitle{
  font-family: 'Bebas Neue Regular';
  color: #333333;
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
}
.case-content{
  color: #333333;
  opacity: 0.5;
}
.rounded-pill {
  font-family: 'DMSans-Regular';
}
.u-title {
  font-family: 'Bebas Neue';
  letter-spacing: 1px;
  font-size: 58px !important;
}
.u-logo-dark span {
  font-family: 'Bebas Neue Regular';
  letter-spacing: 2px;
}
</style>