<template>
  <div style="background:#333;color:#fff;" class="py-16">
    <v-container>
      <div class="text-uppercase text-center" style="font-size:28px;">AGX aims to</div>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.mobile?12:4" class="feature">
          <div class="f-logo">
            <img src="../assets/img/aims/tachometer-alt-solid.png"></img>
          </div>
          <div class="f-title">BOOST INCLUSION</div>
          <div class="f-subtitle">Boost financial inclusion, particularly for over three hundred and fifty million people in Africa who remain unbanked and underserved.</div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile?12:4" class="feature">
          <div class="f-logo">
            <img src="../assets/img/aims/fluent_arrow-expand-24-regular.png"></img>
          </div>
          <div class="f-title">EXPAND FINANCING</div>
          <div class="f-subtitle">Expand affordable and efficient financial services to the continent’s forty million+ SMEs, and address the US$300+ billion SME funding gap.</div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile?12:4" class="feature">
          <div class="f-logo">
            <img src="../assets/img/aims/icon-park-outline_stock-market.png"></img>
          </div>
          <div class="f-title">FOSTER TRADE</div>
          <div class="f-subtitle">Enable cross border trade and investment – increase intra-Africa trade from 17% by leveraging a well de-risk and collaterised medium of exchange which ensures price stability and value preservation for  individual or business payments and transactions.</div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile?12:3" class="feature">
          <div class="f-logo">
            <img src="../assets/img/aims/eos-icons_system-image-outlined.png"></img>
          </div>
          <div class="f-title">EASE MONETARY PRESSURES</div>
          <div class="f-subtitle">Ease pressures on the monetary systems/current account balances of many African economies. </div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile?12:3" class="feature">
          <div class="f-logo">
            <img src="../assets/img/aims/ant-design_dollar-outlined.png"></img>
          </div>
          <div class="f-title">Reduce Cost</div>
          <div class="f-subtitle">Reduce transaction cost and inefficiencies inherent in traditional financial services and remittances.</div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile?12:3" class="feature">
          <div class="f-logo">
            <img src="../assets/img/aims/icon-park-outline_market-analysis.png"></img>
          </div>
          <div class="f-title">DEEPEN MARKETS</div>
          <div class="f-subtitle">Deepen financial markets via new capital formations.</div>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile?12:3" class="feature">
          <div class="f-logo">
            <img src="../assets/img/aims/gg_work-alt.png"></img>
          </div>
          <div class="f-title">CREATE JOBS</div>
          <div class="f-subtitle">Create hundreds of thousands of jobs and opportunities, especially for women and youth.</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.feature{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.feature .f-logo{
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 16px;
  background: #F9BF16;
}
.feature .f-logo img{
  width: 40px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.feature .f-title{
  color:#fff;
  font-size:20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.feature .f-subtitle{
  color:#fff;
  opacity: 0.5;
  max-width:321px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
}
.text-uppercase {
  font-family: 'Bebas Neue';
}
.f-title {
  font-family: 'Bebas Neue Regular';
  font-weight: 100 !important;
  letter-spacing: .5px;
}
</style>