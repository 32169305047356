<template>
  <div class="background">
    <v-container class="background content d-flex justify-center align-center"
      :class="$vuetify.breakpoint.mobile?'flex-column':'flex-row'">
      <div class="banner-text">
        <div class="banner-title text-uppercase">
          <span class="d-block">Enabling more than one billion people</span>
        </div>
        <div class="white--text banner-subtitle mt-6">
          <span>and over 40 million businesses access affordable</span>
          <p>and efficient financial products anywhere anytime</p>
        </div>
        <div class="d-flex flex-row mt-6" v-if="!$vuetify.breakpoint.mobile">
          <v-btn color="#F9BF16" fab depressed width="48" height="48"
            href="https://www.facebook.com"
            style="z-index:10;">
            <img style="height:16px;" src="../assets/img/banner/facebook.png">
          </v-btn>
          <v-btn class="ml-2" color="#F9BF16" fab depressed width="48" height="48"
            href="https://www.twitter.com"
            style="z-index:10;">
            <img style="height:16px;" src="../assets/img/banner/twitter.png">
          </v-btn>
          <v-btn class="ml-2" color="#F9BF16" fab depressed width="48" height="48"
            href="https://www.instagram.com"
            style="z-index:10;">
            <img style="height:16px;" src="../assets/img/banner/instagram.png">
          </v-btn>
        </div>
      </div>
      <div style="flex:1;z-index: 10;"
        :class="$vuetify.breakpoint.mobile?'mt-8':''">
        <img style="width:100%;" src="../assets/img/banner/banner-img.png"></img>
      </div>
    </v-container>
    <div v-if="!$vuetify.breakpoint.mobile" class="triangle"></div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.background{
  background: #333333;
  position: relative;
}
.content{
  padding-top: 80px;
  padding-bottom: 80px;
}
.banner-text{
  max-width:470px;
}
.banner-title{
  color:#F9BF16;
  font-size: 40px;
  line-height: 1.1;
  font-weight: bold;
}
.banner-subtitle{
  font-size: 16px;
}
.social-btn{
  background: #F9BF16;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
}
.triangle{
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  height:0;
  width: 0;
  border-bottom: 60vh solid #fff;
  border-left: 100vw solid transparent;
}
.d-block {
  font-family: 'Bebas Neue';
  font-size: 60px;
  /* width: 500px; */
}
.banner-subtitle span,.banner-subtitle p{
  letter-spacing: 1px;
}
</style>