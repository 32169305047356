import request from '@/utils/ruquest.js'

// 底部发送邮箱
export const emailApi = (data) => {
  // 因为是异步请求所以必须return 否则返回值为undefind
  return request({
    url: '/mail',
    method: 'POST',
    data: {
      text: 'name:' + '\t' + data.username + '\n' + 
            'contact no:' + '\t' +  data.Contact + '\n' + 
            'email address:' + '\t' + data.emailArs + '\n' +  
            'subject:' + '\t' + data.Subject + '\n' + 
            'your message:' + '\t' + data.msg,
      subject: data.Subject, // 邮箱主题
      to: 'Kiel@africaglobalxchange.com' // 发送到此邮箱
      // to: '1009093189@qq.com'
    }
  })
}

// RefistForm 发送邮箱
export const emailsApi = (data) => {
  return request({
    url: '/mail',
    method: 'POST',
    data: {
      text: 'name:' + '\t' + data.username + '\n' + 
            'Surname:' + '\t' +  data.Surname + '\n' + 
            'email address:' + '\t' + data.Email + '\n' +  
            'Phone no:' + '\t' + data.Number + '\n' + 
            'friends details:' + '\t' + data.msg,
      subject: 'registration form',
      to: 'Kiel@africaglobalxchange.com'
    }
  })
}
